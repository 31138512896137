<template>
  <div>
    <div class="table-page-title">
      <div class="fl">
        <el-form :model="searchForm" class="search-form" :inline="true">
          <el-form-item label="" prop="name">
            <el-input class="small-input" v-model="searchForm.name" placeholder="单位名称"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="fl search-form-btn">
        <el-button class="fl" size="small" type="primary" @click="search()">搜 索</el-button>
        <el-button class="fl" size="small" @click="reset()">重 置</el-button>
      </div>
      <div class="ft">
        <el-button size="small" type="primary" @click="chairConfig">轮椅配置</el-button>
        <el-button size="small" type="primary" @click="addUnit">新增单位</el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table
          class="table"
          height="100%"
          v-loading="tableLoading"
          :data="tableData">
        <el-table-column
            label="单位id"
            prop="departmentId"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="单位名称"
            prop="name"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
<!--        <el-table-column-->
<!--            label="楼宇名称"-->
<!--            prop="building"-->
<!--            align="center"-->
<!--            header-align="center"-->
<!--            :formatter="commonFormatter"-->
<!--        >-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            label="楼层"-->
<!--            prop="floor"-->
<!--            align="center"-->
<!--            header-align="center"-->
<!--            :formatter="commonFormatter"-->
<!--        >-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            label="位置"-->
<!--            prop="place"-->
<!--            align="center"-->
<!--            header-align="center"-->
<!--            :formatter="commonFormatter"-->
<!--        >-->
<!--        </el-table-column>-->
        <el-table-column
            width="500"
            align="center"
            header-align="center"
            label="操作">
          <template slot-scope="{row}">
            <el-button type="text" @click="goDeviceList(row.name)">单位设备</el-button>
            <el-button type="text" @click="goOrderList(row.name)">单位订单</el-button>
            <el-button type="text" @click="goUnitDetail(row.departmentId)">单位详情</el-button>
<!--            <el-button type="text" @click="updatedepartment(row.departmentId)">编辑</el-button>-->
           <!-- <el-button type="text" @click="deleteDepartment(row.departmentId,row.positionId)">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        class="top_10 right right_10"
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import tableUse from "@/mixins/tableUse";
import {resetMenuList, setMenuList} from "@/assets/js/common";

export default {
  name: "SiteList",
  mixins: [tableUse],
  data() {
    return {
      searchForm: {
        name: ''
      },
      tableData: [
      ]
    }
  },
  methods: {
    updatedepartment(departmentId){
      // setMenuList({
      //   path: '/addUnit',
      //   name: '新增单位'
      // })
      this.$router.push({name: 'AddUnit',query:{departmentId:departmentId}})
    },
    deleteDepartment(departmentId,positionId){
      this.tableLoading = true;
      this.$axios.post("/sys-api/site-api/deleteDepartment",{id:positionId},(res) => {
        this.tableLoading = false;
        if(res.code === '100'){
          this.$message.success("删除成功")
          this.getTableData()
        }
        else {
          this.$message.error("删除失败！")
        }

      })
    },
    reset() {
      this.searchForm = {
        name: ''
      }
      this.search();
    },
    addUnit() {
      setMenuList({
        path: '/addUnit',
        name: '新增单位'
      })
      this.$router.push({name: 'AddUnit'})
    },
    chairConfig() {
      setMenuList({
        path: '/chairConfig',
        name: '轮椅配置'
      })
      this.$router.push({name: 'ChairConfig'})
    },
    goUnitDetail(departmentId) {
      setMenuList({
        path: '/unitDetail',
        name: '单位详情'
      })
      this.$router.push({name: 'UnitDetail',query:{id:departmentId}})
    },
    goDeviceList(departmentName) {
      this.$router.push({
        name: 'DeviceList',
        params:{departmentName}
      })
    },
    goOrderList(department) {
      this.$router.push({
        name: 'OrderList',
        params:{department}
      })
    },
    getTableData(){
      this.tableLoading = true;
      let formData = {
        pageNum:this.pageNum,
        pageSize: this.pageSize
      }
      if(this.searchForm.name) formData.name = this.searchForm.name;
      this.$axios.post("/sys-api/site-api/siteListPage",formData,(res) => {
        this.tableLoading = false;
        if(res.code === '100'){
          this.total = res.data.total;
          this.tableData = res.data.list;
        }
      })
    }
  },
  mounted() {
    this.getTableData();
  }
}
</script>

<style scoped lang="scss">

</style>
